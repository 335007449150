import { useEffect, useRef, useState } from 'react';

const thresholds = [...new Array(100).keys()].map((value) => value / 100);

type IntersectionObserverOptions = IntersectionObserverInit & {
  viewLimit?: number;
};

const DEFAULT_OPTIONS: IntersectionObserverOptions = {
  root: null,
  rootMargin: '0px',
  threshold: thresholds,
  viewLimit: 0.5,
};

const useIntersectionObserver = (options: IntersectionObserverOptions) => {
  const { viewLimit, ...intersectionOptions } = {
    ...DEFAULT_OPTIONS,
    ...options,
  };
  const [isVisible, setIsVisible] = useState(false);
  const [intersectionRatio, setIntersectionRatio] = useState(0);
  const elementRef = useRef<any>(null);

  useEffect(() => {
    const intersectionCallback: IntersectionObserverCallback = ([entry]) => {
      const ratio = entry.intersectionRatio;
      setIntersectionRatio(ratio);
      setIsVisible(ratio >= (viewLimit || 0));
    };

    const observer = new IntersectionObserver(
      intersectionCallback,
      intersectionOptions,
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    elementRef.current && observer.observe(elementRef.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => elementRef.current && observer.unobserve(elementRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersectionOptions]);

  return { elementRef, isVisible, intersectionRatio };
};

export default useIntersectionObserver;
