/* eslint-disable @typescript-eslint/no-unused-expressions */
import Icons from 'components/icons';
import Image from 'next/image';
import React, { FC, useEffect, useState } from 'react';
import Text from 'components/ui/Text';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import Vimeo from '@u-wave/react-vimeo';
import { useRouter } from 'next/router';

type VimeoPlayerProps = {
  id: string;
  paused?: boolean;
  onPlay?: () => void;
  onPause?: () => void;
  posterImageSrc?: string;
  title?: string;
};

const VimeoPlayer: FC<VimeoPlayerProps> = ({
  id,
  paused,
  onPlay,
  onPause,
  posterImageSrc,
  title,
}) => {
  const { elementRef, isVisible } = useIntersectionObserver({});
  const [hasStarted, setHasStarted] = useState(false);
  const { locale = 'en' } = useRouter();

  useEffect(() => {
    if (!isVisible) {
      onPause && onPause();
    }
  }, [isVisible, onPause]);

  const handleOnPlay = () => {
    onPlay && onPlay();
  };

  const handleOnPause = () => {
    onPause && onPause();
  };

  return (
    <div ref={elementRef} className="vimeo-player relative aspect-video">
      <Vimeo
        paused={paused}
        onPlay={handleOnPlay}
        onPause={handleOnPause}
        video={id}
        textTrack={locale !== 'en' ? locale : undefined}
      />

      {posterImageSrc && !hasStarted && (
        <>
          <Image
            alt={posterImageSrc}
            src={posterImageSrc}
            fill
            className="cursor-pointer object-cover"
            onClick={() => {
              setHasStarted(true);
              handleOnPlay();
            }}
          />
          <Icons.Play className="absolute pointer-events-none text-white/80 h-14 md:h-24 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
        </>
      )}

      {title && !hasStarted && (
        <Text
          bold
          size="xlarge"
          elementType="div"
          color="white"
          className="absolute bottom-10 left-10"
        >
          {title}
        </Text>
      )}
    </div>
  );
};

export default VimeoPlayer;
